/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}


.titleBurst {
    position: relative;
    animation: moveText 0.6s 1;
    animation-direction: normal;
    font-size: 32pt;
    font-weight: bold;
}

@keyframes moveText {
    0% {
        bottom: 10px;
        font-size: 2pt;
        opacity: 0.0;
    }

    20% {
        bottom: 30px;
        font-size: 8pt;
        opacity: 0.1;
    }

    40% {
        bottom: 60px;
        font-size: 14pt;
        opacity: 0.2;
    }

    60% {
        bottom: 20px;
        font-size: 20pt;
        opacity: 0.4;
    }

    80% {
        bottom: -10px;
        font-size: 26pt;
        opacity: 0.5;
    }

    100% {
        bottom: 0px;
        font-size: 32pt;
        opacity: 1.0;
    }
}

.componentPurpose {
    color: darkblue;
    font-size:16pt;
    font-weight:bold; 
}

.required:after {
    content: " *";
    color: red;
    font-weight: bold;
}

.enlarge {
    animation: commonEnlarge 2s 1;
}

@keyframes commonEnlarge {
    0% {
        height: 0%;
    }

    100% {
        height: 100%;
    }
}

.fadeIn {
    animation: commonFadeIn 3s 1;
}

@keyframes commonFadeIn {
    0% {
        opacity: 0.0;
    }

    100% {
        opacity: 1.0;
    }
}

.orgPurpose {
    color: firebrick;
}


.slideInFromRight {
    position: relative;
    animation: slideFromRight 2s 1;
    animation-direction: normal
}

@keyframes slideFromRight {
    0% {
        left: 1500px;
    }

    100% {
        left: 0px;
    }
}


.slideInFromLeft {
    position: relative;
    animation: slideFromLeft 2s 1;
    animation-direction: normal
}

@keyframes slideFromLeft {
    0% {
        right: 1500px;
    }

    100% {
        right: 0px;
    }
}


.slideInFromTop {
    position: relative;
    animation: slideFromTop 1s 1;
    animation-direction: normal
}

@keyframes slideFromTop {
    0% {
        bottom: 500px;
    }

    100% {
        bottom: 0px;
    }
}
