
.copterMove1 {
    position: relative;
    animation: moveThatCopter 18s 1;
    animation-direction: normal;
    height: 20px;
    opacity: 0.0;
    margin-top: 10px;
}

.copterMove2 {
    position: relative;
    animation: moveThatCopter 19s 1;
    animation-direction: normal;
    height: 40px;
    opacity: 0.0;
}

.copterMove3 {
    position: relative;
    animation: moveThatCopter 20s 1;
    animation-direction: normal;
    height: 30px;
    opacity: 0.0;
}

@keyframes moveThatCopter {
    0% {
        bottom: 300px;
        opacity: 1.0;
        left: 0px;
    }

    20% {
        bottom: 300px;
        opacity: 1.0;
        left: 0px;
    }

    60% {
        bottom: 0px;
        opacity: 1.0;
        left: 260px;
    }

    99% {
        bottom: 0px;
        opacity: 1.0;
        left: 4000px;
    }

    100% {
        bottom: 0px;
        opacity: 0.0;
        left: 4000px;
    }
}

/*@keyframes moveThatCopter {
    0% {
        right: 1000px;
        left: 0px;
        opacity: 0.0;
    }

    1% {
        right: 1000px;
        left: 0px;
        opacity: 1.0;
    }

    99% {
        right: 0px;
        left: 4000px;
        opacity: 1.0;
    }

    100% {
        right: 0px;
        left: 4000px;
        opacity: 0.0;
    }
}
*/
.tankMove {
    position: relative;
    animation: moveThatTank 8s 1;
    animation-direction: normal
}

@keyframes moveThatTank {
    0% {
        bottom: 80px;
        right: 1000px;
        opacity: 0.0;
    }

    50% {
        bottom: 20px;
        opacity: 0.6;
    }


    100% {
        bottom: 0px;
        right: 0px;
        opacity: 1.0;
    }
}

.fireRound {
    position: relative;
    animation: fireThatRound 9s 5;
    animation-direction: normal;
    height: 70px;
}

@keyframes fireThatRound {
    0% {
        opacity: 0.0;
    }

    94% {
        opacity: 0.0;
    }


    100% {
        opacity: 1.0;
    }
}

.canonRound {
    position: relative;
    animation: canonRoundTrace 9s 5;
    animation-direction: normal;
    height: 10px;
}

@keyframes canonRoundTrace {
    0% {
        opacity: 0.0;
        left: 0px;
        right: 0px;
    }

    94% {
        opacity: 0.0;
        left: 0px;
        right: 30px;
    }

    97% {
        opacity: 0.6;
        left: 0px;
        right: 10px;
    }

    100% {
        opacity: 1.0;
        left: 3000px;
        right: 0px;
    }
}




.serviceBlock {
    padding: 4px;
    height: 340px;
    animation: serviceBlockFadeIn 6s 1;
}

@keyframes serviceBlockFadeIn {
    0% {
        opacity: 0.0;
    }

    100% {
        opacity: 1.0;
    }
}

.marineBackgroundImage {
    background-image: url(../asset/marine.jpg);
    background-size: cover;
}

.marineWeaponBackgroundImage {
    background-image: url(../asset/marineWeapon.jpg);
    background-size: cover;
}

.armyBackgroundImage {
    background-image: url(../asset/army.jpg);
    background-size: cover;
}

.armyWeaponBackgroundImage {
    background-image: url(../asset/armyWeapon.jpg);
    background-size: cover;
}

.marineBackgroundImage {
    background-image: url(../asset/marine.jpg);
    background-size: cover;
}

.navyBackgroundImage {
    background-image: url(../asset/navy.jpg);
    background-size: cover;
}

.navyWeaponBackgroundImage {
    background-image: url(../asset/navyWeapon.jpg);
    background-size: cover;
}

.airforceBackgroundImage {
    background-image: url(../asset/airforce.jpg);
    background-size: cover;
}

.airforceWeaponBackgroundImage {
    background-image: url(../asset/airforceWeapon.jpg);
    background-size: cover;
}


.rockTank {
    animation: shake 9s 5;
}

@keyframes shake {
    0% {
        transform: translateX(0px)
    }

    1% {
        transform: translateX(-3px);
    }

    2% {
        transform: translateX(1px);
    }

    6% {
        transform: translateX(0px);
    }

    100% {
        transform: translateX(0px);
    }
}

.rockText {
    position: relative;
    animation: rockThatText 9s 6;
    animation-direction: normal;
}

@keyframes rockThatText {
    0% {
        transform: rotateZ(3deg)
    }

    3% {
        transform: rotateZ(0deg)
    }

    100% {
        transform: rotateZ(0deg)
    }
}



.rockTopText {
    position: relative;
    animation: rockThatTopText 9s 6;
    animation-direction: normal;
}

@keyframes rockThatTopText {
    0% {
        transform: rotateZ(-3deg)
    }

    3% {
        transform: rotateZ(0deg)
    }

    100% {
        transform: rotateZ(0deg)
    }
}