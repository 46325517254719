a.navbar-brand {
  white-space: normal;
  text-align: center;
  word-break: break-all;
}

html {
  font-size: 14px; 
}

@media (width: 768px) {
  html {
    font-size: 16px;
  }
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .35);
}

.exploreDetail {
    margin: 0px 0px 2px 3px;
    color:firebrick;
}

.navMenuStyle {
    position: fixed;
    width: 100%;
    z-Index: 2;
    background-color: white;
    animation: displayMenu 4s 1;
    font-size:12pt;
    font-weight: bold;
}

@keyframes displayMenu {
    0% {
        opacity: 0.0;
    }

    100% {
        opacity: 1.0;
    }
}