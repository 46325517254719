.donateTR {
    margin: 2px;
}

.joinTD {
    font-size: 18pt;
    font-weight: bold;
    margin: 6px;
    height: 120px;
    text-align: center;
    border: 8px;
    padding: 8px;
    cursor: pointer;
    width: 25%;
}
 
.donateTable {
    width: 100%;
}

.termButton { 
    background-color: whitesmoke;
    color: navy;
    font-weight: bold; 
    height:130px;
    width:200px;
    margin:auto;
    border: 1px solid black;
    cursor:pointer;
}

.joinButton {
    height: 60px;
    width: 300px;
    background-color: green;
    color: white;
    font-weight: bold;
    font-size: 16pt;
    margin: auto;
}

.termLength {
    margin: auto;
    padding-top:6px;
    text-align:center;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.termAmount {
    margin: auto; 
    text-align: center;
    font-size:32pt;
    font-weight:bold;
}

.bestValue {
    background-color:darkred;
    color:white;
    text-align:center;
    font-weight:bolder;
    font-size: 12pt;
    width:100%;
}

.normalValue {
    padding-top:30px;
}

[type="checkbox"] {
    vertical-align: middle;
}

label {
    margin-top: 4px;
}

.noPadding {
    padding: 0px;
}


.expand {
    animation: divExpand 6s 1;
}

@keyframes divExpand {
    0% {
        height: 0%;
    }

    100% {
        height: 100%;
    }
}