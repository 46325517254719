.donateTR {
    margin: 2px;
}

.donateTD {
    font-size: 16pt;
    font-weight: bold;
    background-color:firebrick;
    margin:2px;
    height:50px;
    text-align:center;
    border: 4px solid white;
    color: white;
    cursor: pointer;
    width: 15%;
}

.donateOther {
    font-size: 14pt;
    font-weight: bold;
   /* margin: 2px;
    padding-top: 4px;
    height: 56px;*/
    text-align: center;
    /*border: 4px solid white;*/
    color: white;
    cursor: pointer; 
}

.donateTable {
    width: 100%;
}
 
.contributeButton {
    height: 60px;
    width: 300px;
    background-color: green;
    color: white;
    font-weight: bold;
    font-size: 16pt;
    margin: auto;


}

.error {
    color:orangered;
    font-size: 12pt;
    font-weight:bold;
}

.amountEnter {
    text-align: center;
    font-size: 14pt;
    font-weight: bold; 
}

.sandyHook {
    background-image: url(../asset/sandy-hook-victims.jpg);
    background-size: cover;
    width: 825px;
    height: 348px;
    margin: auto;
}


.AR15 {
    background-size: cover;
    margin: auto;
}